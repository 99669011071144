<template>
  <v-col
    class="project-tasks-tab"
    md="3"
    sm="12"
  >
    <v-card class="pa-3">
      <!-- @blur removes vuetify's default behavior when it adds search value to the list when blur -->
      <v-combobox
        :value="currentProjectTaskTypes"
        :items="allTaskTypes"
        :label="label"
        :search-input.sync="searchValue"
        :disabled="disabled"
        class="secondary-background chips-input"
        hide-selected
        hide-details
        dense
        outlined
        deletable-chips
        multiple
        chips
        item-text="title"
        item-value="id"
        color="#e36418"
        @change="handleTaskTypesChange"
        @blur="clearSearchValue"
      >
        <template #no-data>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                To create a new Task Type, please, input its name and press <kbd>enter</kbd>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-combobox>
    </v-card>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    projectId: {
      type: String,
      required: true,
    },
    disabled: Boolean,
  },
  data() {
    return {
      label: 'Project Task Types',
      searchValue: '',
    };
  },
  computed: {
    ...mapGetters('shared/taskTypes', [
      'allTaskTypes',
      'projectsTaskTypes',
    ]),

    currentProjectTaskTypes() {
      return this.projectsTaskTypes[this.projectId] || [];
    },
  },
  created() {
    this.updateTaskTypes();
  },
  methods: {
    ...mapActions('shared/taskTypes', [
      'addTaskType',
      'deleteTaskType',
      'getAllTaskTypes',
      'getProjectsTaskTypes',
    ]),
    clearSearchValue() {
      this.searchValue = '';
    },
    async handleTaskTypesChange(taskTypes) {
      const isAddition = this.currentProjectTaskTypes.length < taskTypes.length;
      const toggledElem = this.getChangeElement(taskTypes, isAddition);

      if (typeof toggledElem === 'string') {
        if (!toggledElem.trim()) {
          taskTypes.pop();

          return;
        }
      }

      if (isAddition) {
        await this.addTaskType({
          taskTypeTitle: typeof toggledElem === 'string' ? toggledElem : toggledElem.title,
          projectId: this.projectId,
        });

        this.clearSearchValue();
      } else {
        await this.deleteTaskType({
          projectId: this.projectId,
          taskTypeId: toggledElem.id,
        });
      }

      await this.updateTaskTypes();
    },
    getChangeElement(selected, isAddition) {
      if (isAddition) {
        return selected.filter((elem) => !this.currentProjectTaskTypes.includes(elem))[0];
      }

      return this.currentProjectTaskTypes.filter((elem) => !selected.includes(elem))[0];
    },
    async updateTaskTypes() {
      return Promise.all([
        this.getAllTaskTypes(),
        this.getProjectsTaskTypes({ projectIds: [this.projectId] }),
      ]);
    },
  },
};
</script>

<style lang="less">
.project-tasks-tab .v-chip {
  border-radius: 4px;
  height: unset;

  .v-chip__content {
    white-space: normal;
    padding: 4px 0;
  }
}
</style>
