<template>
  <Dialog
    is-persistent
    :title="dialogTitle"
    :dialog="isOpened"
    :confirmation-button-text="confirmationButtonText"
    @confirmationAction="addAssignment"
  >
    <template #content>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row class="pb-4">
          <v-col>
            <v-autocomplete
              :items="availableEmployees"
              :search-input="assignmentItem.searchField"
              class="secondary-background chips-input"
              label="Name"
              item-text="name"
              item-value="id"
              return-object
              outlined
              hide-details
              dense
              :error="isNameError"
              @update:search-input="handleSearchInput"
              @change="employeeSelected($event)"
            />
          </v-col>
        </v-row>
        <v-row class="pb-4">
          <v-col>
            <v-tooltip top>
              <template #activator="{ on }">
                <v-text-field
                  id="assignment-input"
                  v-model="assignmentItem.assignment"
                  class="secondary-background"
                  label="Assignment"
                  type="number"
                  :rules="[
                    (a) =>
                      [0.5, 0.75, 1, 1.5, 2].includes(Number(a)) ||
                      'Correct assignment is required',
                  ]"
                  required
                  hint="a hint"
                  persistent-hint
                  hide-details
                  outlined
                  dense
                  v-on="on"
                />
              </template>
              <span>Тарифная ставка сотрудника на проекте. Может быть: 0.5, 0.75,
                (1), 1.5, 2. По умолчанию, установлено 1. Желательно не
                изменять.</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="pb-4">
          <v-col>
            <v-select
              id="billable-status-input"
              v-model="assignmentItem.billableStatus"
              :menu-props="{ offsetY: true }"
              :items="billableStatusesItems"
              class="secondary-background"
              label="Billable status"
              :rules="[(bs) => !!bs.trim() || 'Billable status is required']"
              hide-details
              required
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row class="pb-4">
          <v-col>
            <v-select
              id="billable-status-input"
              v-model="assignmentItem.status"
              :menu-props="{ offsetY: true }"
              :items="assignmentStatusesItems"
              class="secondary-background"
              label="Assignment status"
              :rules="[(bs) => !!bs.trim() || 'Assignment status is required']"
              hide-details
              required
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pr-2">
            <DatePicker
              ref="startDate"
              v-model="startDate"
              label="Start date"
              :min-date="project.start_date"
              :max-date="project.end_date"
            />
          </v-col>
          <v-col>
            <DatePicker
              ref="endDate"
              v-model="endDate"
              label="End date"
              :min-date="project.start_date"
              :max-date="project.end_date"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
  </Dialog>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import { Dialog, DatePicker } from '@/components/shared';
import assignmentStatuses from '@/constants/assignmentStatuses';
import { billableStatuses } from '@/constants/billableStatuses';
import { projectRolesNames } from '@/constants/roles';
import { DateHelper } from '@/helpers/date.helper';

const getInitialStartDate = () => DateHelper.toIso(moment());
const getInitialEndDate = () => DateHelper.toIso(moment().add(1, 'months'));

const getInitialState = () => ({
  searchField: '',
  assignment: 1,
  billableStatus: billableStatuses.billable,
  status: assignmentStatuses.active,
});

export default {
  components: {
    Dialog,
    DatePicker,
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
    allEmployees: {
      type: Array,
      required: true,
    },
    involvedEmployees: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialogTitle: 'Add Employee',
      confirmationButtonText: 'Add',
      valid: true,
      assignmentItem: getInitialState(),
      employeeId: null,
      startDate: getInitialStartDate(),
      endDate: getInitialEndDate(),
      isNameError: false,
    };
  },
  computed: {
    ...mapGetters('projects/main', ['project']),
    ...mapGetters('shared/roles', ['projectRoles']),

    form() {
      return { ...this.assignmentItem };
    },
    availableEmployees() {
      const involvedEmployeeIds = this.involvedEmployees.map(
        (e) => e.employee.id
      );

      return this.allEmployees.filter(
        (e) => !involvedEmployeeIds.includes(e.id)
      );
    },
    billableStatusesItems() {
      return Object.values(billableStatuses);
    },
    assignmentStatusesItems() {
      return Object.values(assignmentStatuses);
    },
  },
  watch: {
    startDate(value) {
      if (!DateHelper.isDayBefore(value, this.endDate)) {
        const newEndDate = DateHelper.toIso(moment(value).endOf('month'));

        this.endDate = newEndDate;
      }

      this.startDate = value;
    },
  },
  created() {
    document.addEventListener('keydown', this.handleKeypress);
    document.addEventListener('keyup', this.handleKeyup);
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeypress);
    document.removeEventListener('keyup', this.handleKeyup);
  },
  methods: {
    ...mapActions('shared/offices', ['getOffices']),
    ...mapActions('projects/main', ['addProjectAssignment']),

    employeeSelected(value) {
      if (value) {
        this.employeeId = value.id;
        this.isNameError = false;
      } else {
        this.employeeId = null;
        this.isNameError = true;
      }
    },

    async addAssignment(confirmed) {
      if (!confirmed) {
        this.closeDialog();

        return;
      }

      if (!this.validate()) {
        return;
      }

      const assignment = {
        employee_id: this.employeeId,
        assignment: parseFloat(this.assignmentItem.assignment),
        billable_status: this.assignmentItem.billableStatus,
        status: this.assignmentItem.status,
        start_date: this.startDate,
        end_date: this.endDate,
        role_id: this.projectRoles.find(
          (role) => role.title === projectRolesNames.teamMember
        ).id,
      };

      try {
        await this.addProjectAssignment({
          projectId: this.project.id,
          assignment,
        });
        this.$emit('save');
      } finally {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$emit('close');
      this.reset();
    },
    reset() {
      Object.assign(this.assignmentItem, getInitialState());
      this.employeeId = null;
      this.$refs.startDate.date = getInitialStartDate();
      this.$refs.endDate.date = getInitialEndDate();
      this.resetValidation();
    },
    validate() {
      if (!this.employeeId) {
        this.isNameError = true;
      }

      return this.$refs.form.validate() && !this.isNameError;
    },
    handleSearchInput(value) {
      this.assignmentItem.searchField = value;
    },
    handleKeypress(event) {
      if (event.key === 'Escape') {
        event.preventDefault();
        this.closeDialog();
      }
    },
    handleKeyup(event) {
      if (event.key === 'Enter' && event.ctrlKey) {
        event.preventDefault();
        this.addProject();
      }
    },
    resetValidation() {
      this.$refs.form.resetValidation();
      this.isNameError = false;
    },
  },
};
</script>
