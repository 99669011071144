<template>
  <v-col
    class="project-role-input"
    sm="12"
  >
    <v-autocomplete
      :key="deletingRestrictedToDeleteEmployee"
      :value="selected"
      :items="items"
      :label="role.title"
      :search-input="searchField"
      :disabled="disabled"
      :hide-selected="hideSelected"
      class="secondary-background chips-input"
      item-text="name"
      item-value="id"
      return-object
      multiple
      outlined
      hide-details
      dense
      @update:search-input="handleSearchInput"
      @change="roleListChanged(selected, $event)"
    >
      <template #selection="data">
        <v-chip
          :key="data.item.id"
          label
          :input-value="data.selected"
          :close="!disabled && isCanBeDeleted(data.item.id)"
          class="chip--select-multi"
          @update:active="roleChipDeleted(data.item)"
        >
          <v-icon
            v-if="isFromTheodolite(data.item.id)"
            class="material-icons-outlined theodolite-icon"
          >
            camera_rear
          </v-icon>
          {{ data.item.name }}
        </v-chip>
      </template>
      <template #item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item" />
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title v-text="data.item.name" />
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { CollectionsHelper } from '@/helpers/collections.helper';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    role: {
      type: Object,
      required: true,
    },
    disabled: Boolean,
    hideSelected: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchField: '',
      deletingRestrictedToDeleteEmployee: 0,
    };
  },
  computed: {
    selectedEmployeesMap() {
      /* eslint-disable no-param-reassign */
      return this.selected.reduce((selectedMap, selectedEmployee) => {
        selectedMap[selectedEmployee.id] = selectedEmployee;

        return selectedMap;
      }, {});
      /* eslint-enable */
    },
  },
  methods: {
    roleListChanged(oldRolesList, newRolesList) {
      if (oldRolesList.length === newRolesList.length) return; // no changes

      const roleAddedToTheList = oldRolesList.length < newRolesList.length;
      const difference = CollectionsHelper.difference(oldRolesList, newRolesList);

      if (!difference[0].isCanBeDeleted && !roleAddedToTheList) {
        this.deletingRestrictedToDeleteEmployee += 1; // temporary solution. See TTS-618

        return;
      }

      this.$emit(roleAddedToTheList ? 'added' : 'removed', difference[0], this.role.id);

      setTimeout(() => {
        this.searchField = '';
      }, 0);
    },
    roleChipDeleted(elem) {
      this.$emit('removed', elem, this.role.id);
    },
    handleSearchInput(value) {
      this.searchField = value;
    },
    isFromTheodolite(employeeId) {
      const selectedEmployee = this.selectedEmployeesMap[employeeId];

      return !!selectedEmployee && !!selectedEmployee.from_theodolite;
    },
    isCanBeDeleted(employeeId) {
      const selectedEmployee = this.selectedEmployeesMap[employeeId];

      return !!selectedEmployee && selectedEmployee.isCanBeDeleted;
    },
  },
};
</script>

<style lang="less">
.project-role-input {
  .v-chip {
    .theodolite-icon {
      font-size: 18px !important;
      margin-right: 4px !important;
    }
  }
}
</style>
