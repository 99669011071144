<template>
  <v-row id="project-roles-tab">
    <v-col
      xl="2"
      lg="3"
    >
      <AssignmentsTableFilters />

      <v-card class="pa-4">
        <v-card-title class="small-title">Supervisors</v-card-title>
        <div class="inputs-column">
          <template v-for="projectRole in projectRolesWithoutTeamMember">
            <ProjectRoleInput
              :key="projectRole.id"
              :role="projectRole"
              :selected="employeesByRoles[projectRole.id]"
              :items="isUserOnlyProjectViewer ? employeesByRoles[projectRole.id] : employees"
              :disabled="!allowedToUpdateProjectEmployee || projectRole.title === projectRolesNames.deliveryManager"
              @added="roleAdded"
              @removed="roleRemoved"
            />
          </template>
        </div>
      </v-card>
    </v-col>
    <v-col
      class="pl-1"
      xl="10"
      lg="9"
    >
      <ProjectAssignments
        :all-employees="employees"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { loadingTypes } from '@/constants/loadingTypes';
import { projectRolesNames, projectRoles } from '@/constants/roles';
import { CollectionsHelper } from '@/helpers/collections.helper';
import apiClient from '@/services/api-client';

import AssignmentsTableFilters from './AssignmentsTableFilters.vue';
import ProjectAssignments from './ProjectAssignments.vue';
import ProjectRoleInput from './ProjectRoleInput.vue';

export default {
  components: {
    ProjectAssignments,
    AssignmentsTableFilters,
    ProjectRoleInput,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    allowedToUpdateProjectEmployee: Boolean,
  },
  data() {
    return {
      employeesByRolesWithoutTeamMembers: {},
      employeesByProjectOffices: [],
      projectRolesNames,
    };
  },
  computed: {
    ...mapGetters('auth/account', [
      'isUserAdmin',
      'isUserEmployee',
      'user',
    ]),
    ...mapGetters('projects/main', [
      'projectAssignments',
      'isShowOnlyMyOffice',
    ]),
    ...mapGetters('shared/roles', [
      'projectRoles',
      'projectRolesMap',
    ]),

    isUserOnlyProjectViewer() {
      if (!this.isUserEmployee || !Object.keys(this.employeesByRoles).length) return false;

      const projectViewerRole = this.projectRolesMap[projectRolesNames.projectViewer];

      return this.isSingleProjectRoleUser(projectViewerRole);
    },
    projectRolesWithoutTeamMember() {
      return this.projectRoles.filter((projectRole) => projectRole.title !== projectRolesNames.teamMember);
    },
    employees() {
      return this.isShowOnlyMyOffice ? this.employeesFromOffice : this.allEmployees;
    },
    employeesByRoles() {
      return this.isShowOnlyMyOffice ? this.employeesFromOfficeByRoles : this.allEmployeesByRoles;
    },
    employeesFromOffice() {
      if (!this.allEmployees.length) return [];

      return this.allEmployees.filter((employee) => employee.office_id === this.user.office);
    },
    employeesFromOfficeByRoles() {
      if (!Object.keys(this.employeesByRolesWithoutTeamMembers).length) return {};

      const employeesFromOfficesByRoles = {};

      this.projectRolesWithoutTeamMember.forEach((projectRole) => {
        const employeesByProjectRole = this.allEmployeesByRoles[projectRole.id];
        const employeesFromOfficeByRole = employeesByProjectRole
          .filter((employee) => employee.office_id === this.user.office);

        employeesFromOfficeByRole.sort(this.sortEmployeesByIsCanBeDeleted);
        employeesFromOfficesByRoles[projectRole.id] = employeesFromOfficeByRole;
      });

      return employeesFromOfficesByRoles;
    },
    allEmployees() {
      if (!this.employeesByProjectOffices.length) return [];

      const employees = [];
      const employeesMap = {};

      this.employeesByProjectOffices.forEach((employee) => {
        employees.push(employee);
        employeesMap[employee.id] = employee;
      });

      if (!this.isUserAdmin && Object.keys(this.employeesByRolesWithoutTeamMembers).length) { // === if not admin
        // Adding selected items from another office to employees list
        this.projectRolesWithoutTeamMember.forEach((projectRole) => {
          const employeesByRole = this.employeesByRoles[projectRole.id];

          employeesByRole.forEach((employee) => {
            if (!employeesMap[employee.id]) {
              employees.push(employee);
            }
          });
        });
      }

      return employees;
    },
    allEmployeesByRoles() {
      if (!Object.keys(this.employeesByRolesWithoutTeamMembers).length) return {};

      return this.employeesByRolesWithoutTeamMembers;
    },
  },
  watch: {
    async project() {
      await this.loadProjectDetails();

      const deliveryManagerList = this.employeesByRolesWithoutTeamMembers[projectRoles.deliveryManager.id];
      const isDeliveryManager = deliveryManagerList.find((employee) => employee.id === this.user.id);

      if (isDeliveryManager) {
        this.setIsShowOnlyMyOffice(false);
      }
    },
  },
  created() {
    if (!this.project.id) return;

    this.loadProjectDetails();
  },
  methods: {
    ...mapActions('projects/main', [
      'setEmployeeProjectRole',
      'removeEmployeeFromProject',
      'getProjectAssignments',
    ]),
    ...mapActions('projects/main', ['setIsShowOnlyMyOffice']),
    ...mapActions('shared/roles', ['getProjectRoles']),

    async roleAdded(employee, roleId) {
      await this.setEmployeeProjectRole({ projectId: this.$route.params.id, employeeId: employee.id, roleId });
      await this.addEmployeeToRole(employee, roleId);
      this.$store.cache.delete('auth/account/getUserProjects');
    },
    async roleRemoved(employee, roleId) {
      await this.removeEmployeeFromProject({ projectId: this.$route.params.id, employeeId: employee.id, roleId });
      await this.removeEmployeeFromRole(employee, roleId);
      this.$store.cache.delete('auth/account/getUserProjects');
    },
    addEmployeeToRole(employee, roleId) {
      employee.from_theodolite = false;
      this.setEmployeeIsCanBeDeleted(employee);

      this.employeesByRolesWithoutTeamMembers[roleId].push(employee);
      this.employeesByRolesWithoutTeamMembers[roleId].sort(this.sortEmployeesByIsCanBeDeleted);
    },
    removeEmployeeFromRole(employee, roleId) {
      const projectManagerRole = this.projectRolesMap[projectRolesNames.projectManager];

      if (employee.id === this.user.id && this.isUserEmployee && this.isSingleProjectRoleUser(projectManagerRole)) {
        this.$router.push({ name: 'Projects' });

        return;
      }

      this.employeesByRolesWithoutTeamMembers[roleId] = this.employeesByRolesWithoutTeamMembers[roleId]
        .filter((projectEmployee) => projectEmployee.id !== employee.id);
    },
    sortEmployeesByIsCanBeDeleted(employeeA, employeeB) {
      const sortedByTheodolite = employeeB.from_theodolite - employeeA.from_theodolite;

      if (sortedByTheodolite === 0 && employeeA.from_theodolite === false) {
        return employeeA.isCanBeDeleted - employeeB.isCanBeDeleted;
      }

      return sortedByTheodolite;
    },
    isSingleProjectRoleUser(singleProjectRole) {
      return this.projectRolesWithoutTeamMember.every((projectRole) => {
        const employeesByRole = this.employeesByRoles[projectRole.id];
        const userExistsOnRole = employeesByRole.find((employee) => employee.id === this.user.id);

        if (projectRole.id === singleProjectRole.id) {
          if (userExistsOnRole) return true;

          return false;
        }

        if (userExistsOnRole) return false;

        return true;
      });
    },
    setEmployeeIsCanBeDeleted(employee) {
      const userOffice = this.user.office;

      const employeeOffice = employee.office_id || userOffice;

      const isFromTheodolite = employee.from_theodolite || false;
      const isCanBeDeleted = !isFromTheodolite && (employeeOffice === userOffice || this.isUserAdmin);

      employee.isCanBeDeleted = isCanBeDeleted;
    },
    async loadEmployees() {
      const userAssignmentsInProject = this.project.employees.filter((e) => e.id === this.user.id);
      let isUserNotOnlyProjectViewer = true;

      if (userAssignmentsInProject.length) {
        isUserNotOnlyProjectViewer = userAssignmentsInProject.some((assignment) => {
          const assignmentRole = this.projectRolesMap[assignment.project_role_id];

          const isNotTeamMember = assignmentRole.title !== projectRolesNames.teamMember;
          const isNotProjectViewer = assignmentRole.title !== projectRolesNames.projectViewer;

          return isNotTeamMember && isNotProjectViewer;
        });
      }

      // Is we need to get employees for no-employees and no-projectViewers autocomplete list
      if (!this.isUserEmployee || isUserNotOnlyProjectViewer) {
        let officesIds = [this.user.role.office];

        if (this.isUserAdmin) {
          officesIds = this.project.office.map((office) => office.id);
        }

        const { data: employees } = await apiClient.employeeApi.getEmployees({ officesIds });

        /* eslint-disable no-param-reassign */
        const employeesToDisplay = employees.reduce((employees, employee) => {
          if (employee.status === 'Disabled' || employee.status === 'Deleted'
              || employee.status === 'Former employee') return employees;

          employees.push(employee);
          employees[employee.id] = employee;

          return employees;
        }, []);
        /* eslint-enable */

        this.employeesByProjectOffices = CollectionsHelper.sortObjectsByProperty(
          employeesToDisplay, 'name'
        );
      }

      return Promise.resolve();
    },
    groupProjectAssignmentsByRoles() {
      const employeesByProjectRoles = {};

      this.projectRolesWithoutTeamMember.forEach((projectRole) => {
        employeesByProjectRoles[projectRole.id] = [];
      });

      /* eslint-disable no-param-reassign */
      this.projectAssignments.reduce((employeesByProjectRoles, projectAssignment) => {
        const projectAssignmentRoleId = projectAssignment.project_role_id;
        const employeeRoleTitle = this.projectRolesMap[projectAssignmentRoleId].title;
        const isEmployeeNotTeamMember = employeeRoleTitle !== projectRolesNames.teamMember;

        if (isEmployeeNotTeamMember) {
          const { employee } = projectAssignment;

          employee.from_theodolite = projectAssignment.from_theodolite || false;
          this.setEmployeeIsCanBeDeleted(employee);

          const employeesByProjectRole = employeesByProjectRoles[projectAssignmentRoleId] || [];

          employeesByProjectRole.push(employee);

          employeesByProjectRoles[projectAssignmentRoleId] = employeesByProjectRole;
        }

        return employeesByProjectRoles;
      }, employeesByProjectRoles);
      /* eslint-enable */

      this.projectRolesWithoutTeamMember.forEach((projectRole) => {
        employeesByProjectRoles[projectRole.id].sort(this.sortEmployeesByIsCanBeDeleted);
      });

      this.employeesByRolesWithoutTeamMembers = employeesByProjectRoles;
    },
    async loadProjectDetails() {
      if (!this.projectRoles.length) {
        await this.getProjectRoles();
      }

      await this.getProjectAssignments({
        projectId: this.project.id,
        options: { loadingType: loadingTypes.none },
      });
      await this.loadEmployees();
      this.groupProjectAssignmentsByRoles();
    },
  },
};
</script>
