<template>
  <div>
    <AssignmentsTable
      v-for="{ key, sow_po_number, sow_type } of uniqueSowTypes"
      :key="key"
      class="mb-4"
      :title="'Billable'"
      :items="assignmentsMap[key]"
      :all-employees="allEmployees"
    />
    <AssignmentsTable
      is-invested
      :title="'Invested'"
      :items="investedAssignments"
      :all-employees="allEmployees"
    />
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { mapGetters, mapActions } from 'vuex';

import AssignmentsTable from './AssignmentsTable.vue';

export default {
  components: {
    AssignmentsTable,
  },
  props: {
    allEmployees: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('projects/main', [
      'projectAssignments',
      'isShowOnlyMyOffice',
      'isShowRemoved',
    ]),
    ...mapGetters('auth/account', ['user']),
    ...mapGetters('projects/main', ['search']),

    filteredAssignments() {
      return this.projectAssignments.filter((assignment) => {
        const isFiltered = this.checkIfAssignmentFiltered(assignment);
        const isTeamMember = assignment.project_role_title === 'Team member';
        const isMyOffice = !this.isShowOnlyMyOffice
          || assignment.employee.office_id === this.user.office;
        const isRemoved = !this.isShowRemoved
          && (assignment.status === 'Removed' || assignment.status === 'Deleted');

        return !isFiltered && isTeamMember && isMyOffice && !isRemoved;
      });
    },
    billableAssignments() {
      return this.filteredAssignments.filter(
        ({ billable_status }) => billable_status && billable_status.toLowerCase() === 'billable'
      );
    },
    investedAssignments() {
      return this.filteredAssignments.filter(
        ({ billable_status }) => !billable_status || billable_status.toLowerCase() !== 'billable'
      );
    },

    uniqueSowTypes() {
      const sowTypes = this.filteredAssignments.map(
        ({ sow_po_number, sow_type }) => ({
          sow_po_number,
          sow_type,
          key: `${sow_po_number}_${sow_type}`,
        })
      );
      const uniqueSowTypes = uniqWith(sowTypes, isEqual);

      return uniqueSowTypes.filter((type) => this.assignmentsMap[type.key]);
    },

    assignmentsMap() {
      /* eslint-disable no-param-reassign */
      return this.billableAssignments.reduce((map, assignment) => {
        const { sow_po_number, sow_type } = assignment;
        const key = `${sow_po_number}_${sow_type}`;

        if (map[key]) {
          map[key].push(assignment);
        } else {
          map[key] = [assignment];
        }

        return map;
      }, {});
      /* eslint-enable */
    },
  },
  beforeDestroy() {
    this.clearProjectAssignments();
  },
  methods: {
    ...mapActions('projects/main', ['clearProjectAssignments']),

    checkIfSearchMatch(value) {
      return (
        (value || '').toLowerCase().indexOf(this.search.toLowerCase()) === -1
      );
    },

    checkIfAssignmentFiltered({ employee, sow_po_number, position }) {
      return (
        this.checkIfSearchMatch(employee.name)
        && this.checkIfSearchMatch(sow_po_number)
        && this.checkIfSearchMatch(position)
      );
    },
  },
};
</script>

<style lang="less"></style>
