<template>
  <v-card class="pa-4 mb-4">
    <v-card-title class="small-title">Filters</v-card-title>
    <v-col class="inputs-column">
      <v-text-field
        class="secondary-background"
        label="Name/SOW/Position"
        prepend-inner-icon="search"
        outlined
        dense
        single-line
        hide-details
        :value="search"
        @input="setSearch"
      />
      <v-switch
        ref="filterSwitcher"
        label="Removed Employees"
        color="primary"
        hide-details
        :input-value="isShowRemoved"
        @change="setIsShowRemoved"
      />
      <v-switch
        ref="filterByMyOfficeSwitcher"
        label="Only My Office"
        color="primary"
        hide-details
        :input-value="isShowOnlyMyOffice"
        @change="setIsShowOnlyMyOffice"
      />
    </v-col>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('projects/main', [
      'isShowOnlyMyOffice',
      'isShowRemoved',
      'search',
    ]),
  },
  beforeDestroy() {
    this.setIsShowOnlyMyOffice(true);
    this.setIsShowRemoved(false);
    this.setSearch('');
  },
  methods: {
    ...mapActions('projects/main', [
      'setIsShowOnlyMyOffice',
      'setIsShowRemoved',
      'setSearch',
    ]),
  },
};
</script>

<style lang="less">
</style>
