<template>
  <tr :class="{ shadow: localData.role === 'Shadow' }">
    <td
      v-for="(header, index) in headers.filter((h) => !h.hidden)"
      :key="index"
    >
      <template v-if="header.value === 'employee.name'">
        {{ localData.employee.name }}
      </template>
      <template v-else-if="header.value === 'office_title'">
        {{ localData.employee.office_title }}
      </template>
      <template v-else-if="header.value === 'assignment'">
        {{ localData.assignment }}
      </template>
      <template v-else-if="header.value === 'start_date'">
        <DatePicker
          ref="startDate"
          v-model="localData.start_date"
          :outlined="false"
          :min-date="project.start_date"
          :max-date="project.end_date"
        />
      </template>
      <template v-else-if="header.value === 'end_date'">
        <DatePicker
          ref="endDate"
          v-model="localData.end_date"
          :outlined="false"
          :min-date="project.start_date"
          :max-date="project.end_date"
        />
      </template>
      <template v-else-if="header.value === 'billable_status'">
        <v-select
          id="billable-status-input"
          v-model="localData.billable_status"
          :menu-props="{ offsetY: true }"
          :items="billableStatusesItems"
          class="secondary-background"
          :rules="[(bs) => !!bs.trim() || 'Billable status is required']"
          hide-details
          required
          dense
        />
      </template>
      <template v-else-if="header.value === 'status'">
        <v-select
          id="billable-status-input"
          v-model="localData.status"
          :menu-props="{ offsetY: true }"
          :items="assignmentStatusesItems"
          class="secondary-background"
          :rules="[(bs) => !!bs.trim() || 'Assignment status is required']"
          hide-details
          required
          dense
        />
      </template>
      <template v-else-if="header.value === 'ovr_allowed'">
        <v-checkbox
          :class="`mt-0 pt-0 ${denseTables && 'ripple-scale'}`"
          color="primary"
          hide-details
          :input-value="!!Number(localData.settings.ovr_allowed)"
          @change="changeOVR(assignment, $event)"
        />
      </template>
      <template v-else>
        {{ localData[header.value] }}
      </template>
    </td>
    <td>
      <v-row>
        <v-btn
          text
          icon
          small
          @click.stop="updateAssignment()"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>
        <v-btn
          text
          icon
          small
          @click.stop="cancelEdit()"
        >
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
      </v-row>
    </td>
  </tr>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import { TimeTooltip } from '@/components/shared';
import DatePicker from '@/components/shared/DatePicker.vue';
import MultiChoiceFilter from '@/components/shared/MultiChoiceFilter.vue';
import assignmentStatuses from '@/constants/assignmentStatuses';
import { billableStatuses } from '@/constants/billableStatuses';
import { projectStatusFilterValues } from '@/constants/projectStatuses';
import { projectTypes } from '@/constants/projectTypes';

export default {
  name: 'ProjectAssignmentInput',
  components: {
    DatePicker,
    MultiChoiceFilter,
    TimeTooltip,
  },
  props: {
    assignment: {
      type: Object,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      endDateMenu: false,
      startDateMenu: false,
      localData: this.getInitValue(),
      projectStatusFilterValues,
      projectTypes,
    };
  },
  computed: {
    ...mapGetters('user/settings', ['denseTables', 'dateFormatSettings']),
    ...mapGetters('projects/main', ['project', 'search']),
    assignmentStatusesItems() {
      return Object.values(assignmentStatuses);
    },
    billableStatusesItems() {
      return Object.values(billableStatuses);
    },
  },
  mounted() {},
  methods: {
    cancelEdit() {
      this.$emit('cancel');
    },
    officesSelected(value) {
      this.localData.office_ids = value;
    },
    updateAssignment() {
      this.$emit('save', this.localData);
    },
    navigateToDetails(item) {
      this.$router.push({
        name: 'Project Details',
        params: {
          id: item.id,
          project: item,
        },
      });
    },
    changeOVR(assignment, ovr_allowed) {
      this.$emit('changeOVR', assignment, ovr_allowed);
    },
    getInitValue() {
      const localData = _.cloneDeep(this.assignment);

      localData.employee_id = this.assignment.employee.id;

      return localData;
    },
  },
};
</script>

<style lang="less">
@import "~variables";

.row-editor {
  .project-input {
    max-width: 270px;
  }

  .v-label {
    font-size: @table-font-size;
  }

  .v-text-field__slot {
    font-size: @table-font-size;
  }

  .v-text-field {
    input {
      font-size: @table-font-size;
    }
  }

  .clipped div.v-select__selection {
    overflow: hidden;
    white-space: nowrap;
  }

  // Validation error message
  .v-input {
    .v-text-field__details {
      overflow: visible;
    }

    .v-messages__message {
      position: absolute;
      background: @soft-peach;
      border: 1px solid @pink;
      border-radius: 0 2px 2px 2px;
      width: max-content;
      max-width: 120px;
      padding: 3px;
      color: @red-dark;
      box-shadow: 0 -1px 8px 2px @pink-light;

      &::before {
        content: "";
        position: absolute;
        border: solid transparent;
        border-width: 0 6px 5px 0;
        border-bottom-color: @pink;
        top: -5px;
        left: -1px;
      }
    }
  }
  .time__cell {
    position: relative;
  }
}
</style>
