<template>
  <tr
    v-if="!isEditing && assignment.id"
    :class="{ shadow: assignment.role === 'Shadow' }"
  >
    <td
      v-for="(header, index) in headers.filter((h) => !h.hidden)"
      :key="index"
    >
      <template v-if="header.value === 'employee.name'">
        {{ assignment.employee.name }}
      </template>
      <template v-else-if="header.value === 'office_title'">
        {{ assignment.employee.office_title }}
      </template>
      <template
        v-else-if="header.value === 'start_date' || header.value === 'end_date'"
      >
        {{ assignment[header.value] | dateToDisplay(dateFormatSettings) }}
      </template>
      <template v-else-if="header.value === 'ovr_allowed'">
        <v-checkbox
          :class="`mt-0 pt-0 ${denseTables && 'ripple-scale'}`"
          color="primary"
          hide-details
          :input-value="!!Number(assignment.settings.ovr_allowed)"
          @change="changeOVR(assignment, $event)"
        />
      </template>
      <template v-else>
        {{ assignment[header.value] }}
      </template>
    </td>
    <td v-if="isUserAdmin">
      <template v-if="!isEditing">
        <v-row>
          <v-btn
            text
            icon
            small
            @click.stop="editAssignment()"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            text
            icon
            small
            @click.stop="deleteAssignment(assignment)"
          >
            <v-icon>delete_outline</v-icon>
          </v-btn>
        </v-row>
      </template>
    </td>
  </tr>
  <ProjectAssignmentInput
    v-else
    :key="assignment.id"
    :assignment="assignment"
    :headers="headers"
    @changeOVR="changeOVR"
    @save="saveAssignment"
    @cancel="cancelEdit"
  />
</template>

<script>
import { mapGetters } from 'vuex';

import ProjectAssignmentInput from '@/components/projects/ProjectAssignmentInput.vue';
import { TimeTooltip } from '@/components/shared';
import MultiChoiceFilter from '@/components/shared/MultiChoiceFilter.vue';
import { loadingTypes } from '@/constants/loadingTypes';
import { DateHelper } from '@/helpers/date.helper';

export default {
  name: 'ProjectAssignmentRow',
  components: {
    ProjectAssignmentInput,
    MultiChoiceFilter,
    TimeTooltip,
  },
  filters: {
    dateToDisplay: DateHelper.formatDateAccordingSettings,
  },
  props: {
    selected: {
      type: Boolean,
      required: true,
    },
    select: {
      type: Function,
      required: true,
    },
    assignment: {
      type: Object,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      allowedToUpdateProjectEmployee: false,
      allowedToUpdateProjectTaskTypes: false,
      loadingTypes,
      isLoading: false,
      syncProjectText: 'Sync',
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters('shared/offices', ['offices']),
    ...mapGetters('auth/account', ['isUserAdmin']),
    ...mapGetters('user/settings', [
      'denseTables',
      'dateFormatSettings',
    ]),
  },
  mounted() {},
  methods: {
    cancelEdit() {
      this.setEditing(false);
    },
    saveAssignment(assignment) {
      this.$emit('onSave', assignment);
      this.cancelEdit();
    },
    deleteAssignment(item) {
      this.$emit('onDelete', item);
    },
    selectItem() {
      this.$emit('onSelect', this.assignment);
    },
    setEditing(value) {
      this.isEditing = value;
    },
    editAssignment() {
      this.setEditing(true);
    },
    changeOVR(assignment, ovr_allowed) {
      this.$emit('onChangeOVR', assignment, ovr_allowed);
    },
  },
};
</script>

<style lang="less">
@import "~variables";

.row-editor {
  .project-input {
    max-width: 270px;
  }

  .v-label {
    font-size: @table-font-size;
  }

  .v-text-field__slot {
    font-size: @table-font-size;
  }

  .v-text-field {
    input {
      font-size: @table-font-size;
    }
  }

  .clipped div.v-select__selection {
    overflow: hidden;
    white-space: nowrap;
  }

  // Validation error message
  .v-input {
    .v-text-field__details {
      overflow: visible;
    }

    .v-messages__message {
      position: absolute;
      background: @soft-peach;
      border: 1px solid @pink;
      border-radius: 0 2px 2px 2px;
      width: max-content;
      max-width: 120px;
      padding: 3px;
      color: @red-dark;
      box-shadow: 0 -1px 8px 2px @pink-light;

      &::before {
        content: "";
        position: absolute;
        border: solid transparent;
        border-width: 0 6px 5px 0;
        border-bottom-color: @pink;
        top: -5px;
        left: -1px;
      }
    }
  }
  .time__cell {
    position: relative;
  }
}
</style>
