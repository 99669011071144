<template>
  <v-card>
    <div class="d-flex justify-space-between pa-4 pb-0">
      <v-card-title class="small-title">{{ title }}</v-card-title>
      <Button
        id="add-employee-button"
        color="primary"
        @clickAction="addAssignmentDialog = true"
      >
        {{ "Add employee" }}
      </Button>
    </div>
    <v-data-table
      class="assignments-table pb-4"
      item-key="id"
      disable-pagination
      hide-default-footer
      :headers="isInvested ? investedHeaders : billableHeaders"
      :items="items"
      :dense="denseTables"
      :sort-by="['employee.name']"
    >
      <template #item="{ item, headers, isSelected, select, index }">
        <ProjectAssignmentRow
          :key="index"
          :assignment="item"
          :headers="headers"
          :selected="isSelected"
          :select="select"
          @onDelete="askAssignmentDeleteConfirmation"
          @onChangeOVR="assignmentOVRAllowedChanged"
          @onSave="saveAssignment"
        />
      </template>
    </v-data-table>

    <AddAssignmentDialog
      :is-opened="addAssignmentDialog"
      :all-employees="allEmployees"
      :involved-employees="items"
      @close="addAssignmentDialog = false"
      @save="refreshProjectAssignments"
    />

    <DeletionConfirmationDialog
      :dialog="deleteAssignmentDialog"
      title="Confirmation"
      text="Are you sure you want to delete this employee from the project?"
      confirmation-button-text="Delete"
      @confirmationAction="assignmentDeleteConfirmation"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ProjectAssignmentRow from '@/components/projects/ProjectAssignmentRow.vue';
import {
  Button,
  Dialog as DeletionConfirmationDialog,
} from '@/components/shared';
import { DateHelper } from '@/helpers/date.helper';
import apiClient from '@/services/api-client';

import AddAssignmentDialog from './AddAssignmentDialog.vue';

export default {
  components: {
    ProjectAssignmentRow,
    AddAssignmentDialog,
    Button,
    DeletionConfirmationDialog,
  },
  filters: {
    dateToDisplay: DateHelper.formatDateAccordingSettings,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Employees',
    },
    isInvested: {
      type: Boolean,
      required: false,
      default: false,
    },
    allEmployees: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      investedHeaders: [
        {
          text: 'Name',
          value: 'employee.name',
        },
        {
          text: 'Office',
          value: 'office_title',
          fixed: true,
          width: '1%',
          sortable: false,
        },
        {
          text: 'As.',
          value: 'assignment',
          fixed: true,
          width: '1%',
          sortable: false,
        },
        {
          text: 'Billable status',
          value: 'billable_status',
          fixed: true,
          width: '150px',
          sortable: false,
        },
        {
          text: 'Start Date',
          value: 'start_date',
          fixed: true,
          width: '120px',
          sortable: false,
        },
        {
          text: 'End Date',
          value: 'end_date',
          fixed: true,
          width: '120px',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          fixed: true,
          width: '3%',
          sortable: false,
        },
        {
          text: 'OVR',
          value: 'ovr_allowed',
          width: '1%',
          sortable: false,
        },
        {
          hidden: true,
          width: '100px',
          sortable: false,
        },
      ],
      billableHeaders: [
        {
          text: 'Name',
          value: 'employee.name',
        },
        {
          text: 'Position',
          value: 'position',
          width: '200px',
          sortable: false,
        },
        {
          text: 'Office',
          value: 'office_title',
          fixed: true,
          width: '2%',
          sortable: false,
        },

        {
          text: 'As.',
          value: 'assignment',
          fixed: true,
          width: '2%',
          sortable: false,
        },
        {
          text: 'Billable status',
          value: 'billable_status',
          fixed: true,
          width: '150px',
          sortable: false,
        },
        {
          text: 'Start Date',
          value: 'start_date',
          fixed: true,
          width: '180px',
          sortable: false,
        },
        {
          text: 'End Date',
          value: 'end_date',
          fixed: true,
          width: '180px',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          fixed: true,
          width: '1%',
          sortable: false,
        },
        {
          text: 'OVR',
          value: 'ovr_allowed',
          width: '1%',
          sortable: false,
        },
        {
          hidden: true,
          width: '100px',
          sortable: false,
        },
      ],
      addAssignmentDialog: false,
      deleteAssignmentDialog: false,
      itemToDelete: '',
    };
  },
  computed: {
    ...mapGetters('user/settings', ['denseTables', 'dateFormatSettings']),
    ...mapGetters('projects/main', ['project', 'search']),
    ...mapGetters('auth/account', ['isUserAdmin']),
  },
  methods: {
    ...mapActions('projects/main', [
      'getProjectAssignments',
      'removeEmployeeFromProject',
      'updateProjectAssignment',
    ]),
    ...mapMutations('projects/main', ['setProjectAssignments']),

    askAssignmentDeleteConfirmation(item) {
      this.itemToDelete = item;
      this.deleteAssignmentDialog = true;
    },
    async assignmentDeleteConfirmation(confirmed) {
      if (confirmed) {
        await this.removeEmployeeFromProject({
          projectId: this.project.id,
          employeeId: this.itemToDelete.employee.id,
          roleId: this.itemToDelete.project_role_id,
        });
        await this.refreshProjectAssignments();
      }

      this.deleteAssignmentDialog = false;
    },
    async saveAssignment(assignment) {
      try {
        await this.updateProjectAssignment({
          projectId: this.project.id,
          assignment,
        });
      } catch (error) {
        this.flashError('An error occured while updating assignment.');
      } finally {
        await this.refreshProjectAssignments();
      }
    },
    async assignmentOVRAllowedChanged(assignment, ovr_allowed) {
      ovr_allowed = !!ovr_allowed;
      const previousOVRAllowedValue = assignment.settings.ovr_allowed;

      this.$set(assignment.settings, 'ovr_allowed', ovr_allowed);

      try {
        await apiClient.projectApi.setProjectAssignmentSettings(
          assignment.project_id,
          assignment.id,
          { ovr_allowed }
        );
        this.$store.cache.delete('auth/account/getUserProjects');
      } catch (error) {
        this.flashError(
          'An error occured while requesting "OVR allowed" setting updating for assignment.'
        );
        this.$set(assignment.settings, 'ovr_allowed', previousOVRAllowedValue);
      }
    },
    async refreshProjectAssignments() {
      await this.getProjectAssignments({ projectId: this.project.id });
    },
  },
};
</script>

<style lang="less">
.assignments-table {
  overflow: auto;

  .ripple-scale.v-input--selection-controls:not(.v-input--switch)
    .v-input--selection-controls__ripple {
    transform: scale(0.6);
  }

  table .shadow {
    color: darkgray;
  }
}

.action-column {
  .row {
    margin: 0;

    .v-btn {
      color: #555;
      margin: 1px;
      height: inherit;
      width: inherit;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        transition: all 0.5s ease;
      }
    }
  }
}
</style>
