<template>
  <v-col
    v-if="project"
    id="project-details"
    class="bar-shadow primary-background flex-column-with-tabs"
  >
    <div>
      <v-row class="pa-4">
        <v-col>
          <DetailsHeader
            :loading="isLoading"
            :title="project.title"
            :chip-text="project.status"
            :avatar-icon="projectTypeIcon"
            :avatar-tooltip="`Type: ${projectType}`"
          >
            <template #subtitle>
              <div class="mr-4">
                <template v-if="projectOffices">
                  <v-icon
                    small
                    class="mr-1"
                  >
                    location_city
                  </v-icon>
                  <span>{{ projectOffices }}</span>
                </template>
                <template v-if="dateRange">
                  <v-icon
                    small
                    class="mr-1 ml-4"
                  >
                    date_range
                  </v-icon>
                  <span>{{ dateRange }}</span>
                </template>
              </div>
            </template>
          </DetailsHeader>
        </v-col>
        <!-- ets-rft-017 -->
        <!-- project sync is only using theodolite, which is unavailable rigth now -->
        <!--
        <v-col class="justify-end col-max-width-fit-content">
          <Button
            id="sync-project-button"
            color="primary"
            class="ma-1"
            :action-names="[loadingTypes.syncProjectButton]"
            @clickAction="onProjectSync"
          >
            <span>{{ syncProjectText }}</span>
          </Button>
        </v-col>
         -->
      </v-row>
    </div>

    <v-row>
      <v-tabs
        slider-color="primary"
        class="tabs-bar-shadow"
      >
        <v-tab>
          Project Team
        </v-tab>
        <v-tab-item>
          <ProjectRoleTab
            :project="project"
            :allowed-to-update-project-employee="allowedToUpdateProjectEmployee"
          />
        </v-tab-item>

        <v-tab>
          Project Tasks
        </v-tab>
        <v-tab-item>
          <ProjectTasksTab
            :project-id="$route.params.id"
            :disabled="!allowedToUpdateProjectTaskTypes"
          />
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { Button, DetailsHeader } from '@/components/shared';
import { loadingTypes } from '@/constants/loadingTypes';
import { action, resource } from '@/constants/permissions';
import projectTypesIcons from '@/constants/projectTypesIcons';
import { DateHelper } from '@/helpers/date.helper';
import apiClient from '@/services/api-client';

import ProjectRoleTab from './ProjectRolesTab.vue';
import ProjectTasksTab from './ProjectTasksTab.vue';

export default {
  components: {
    Button,
    DetailsHeader,
    ProjectRoleTab,
    ProjectTasksTab,
  },
  data() {
    return {
      allowedToUpdateProjectEmployee: false,
      allowedToUpdateProjectTaskTypes: false,
      loadingTypes,
      isLoading: false,
      syncProjectText: 'Sync',
    };
  },
  computed: {
    ...mapGetters('user/settings', ['dateFormatSettings']),
    ...mapGetters('projects/main', ['project']),

    projectOffices() {
      return this.project.office ? this.project.office.reduce((res, o, i) => {
        if (i + 1 < this.project.office.length) {
          return res.concat(`${o.title}, `);
        }

        return res.concat(o.title);
      }, '') : '';
    },
    projectType() {
      return this.project.type;
    },
    projectTypeIcon() {
      return projectTypesIcons[this.projectType];
    },
    startDate() {
      return DateHelper.formatDateAccordingSettings(this.project.start_date, this.dateFormatSettings);
    },
    endDate() {
      return DateHelper.formatDateAccordingSettings(this.project.end_date, this.dateFormatSettings);
    },
    dateRange() {
      if (!this.endDate && !this.startDate) {
        return null;
      }

      return this.endDate ? `${this.startDate} - ${this.endDate}` : this.startDate;
    },
  },
  created() {
    this.isLoading = true;
    this.fetchProjectDetails().finally(() => (this.isLoading = false));
  },
  beforeDestroy() {
    this.setProject({});
  },
  methods: {
    ...mapActions('projects/main', [
      'getProjectById',
      'setProject',
    ]),
    ...mapActions('auth/account', ['getUserProjects']),

    async fetchProjectDetails() {
      const projectId = this.$route.params.id;

      try {
        await this.getProjectById({ projectId });
        const res = await Promise.all([
          apiClient.permissionsApi.allowedTo(action.UPDATE, resource.projectEmployee, projectId),
          apiClient.permissionsApi.allowedTo(action.UPDATE, resource.projectTaskTypes, projectId),
        ]);

        this.allowedToUpdateProjectEmployee = res[0].data.allowed;
        this.allowedToUpdateProjectTaskTypes = res[1].data.allowed;
      } catch {
        await this.$router.push('/projects');
      }
    },
    async onProjectSync() {
      await apiClient.syncApi.syncProject(this.project.id);
      this.getProjectById({ projectId: this.project.id });
      this.getUserProjects();
    },
  },
};
</script>

<style lang="less">
  #project-details {
    .offices-text {
      align-self: center;
    }
  }
</style>
